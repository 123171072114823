@import "assets/stylesheets/variables.scss";
@import "assets/stylesheets/mixins.scss";

.chart-wrapper {
    position: relative;
    width: 90%;
}

.naviagtor {
    @include flex-properties(row, nowrap);
    justify-content: space-between;
    margin-top: 16px;

    .navigator-icon {
        width: 24px !important;
        height: 24px !important;
    }
    .left-navigator, .right-navigator {
        display: flex;
        align-items: center;
        &__text {
            padding: 0 !important;
        }
    }
}

.summary-page-divider {
    margin: 0 -15px 0 -15px;
    min-width: 100%;
    height: 4px;
    background-color: $silverGray;
}

.fill-survey-disclaimer-text {
    @include font-properties(10px, 400, normal, #000);
}

.survey-action-text {
    margin-top: 8px;
    @include font-properties(10px, 400, normal, #000);
}
.text-font-small {
    @include font-properties(12px, 400, 14px, $colorBlack);
}

.pain-score-chart-wrapper {
    max-width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding-inline: 16px;
}

.outcomes-card-loader, .pain-score-chart-loader {
    height: 104px;
    align-items: center;
    padding: 0;
}

.outcomes-card-wrapper, .pain-score-chart-loader-wrapper {
    max-width: 100%;
    height: 100%;
    margin-top: -83px;
    padding: 16px;
    padding-bottom: 24px;
    max-height: 104px;
}

.chart-title {
    @include font-properties(16px, 600, 20px, #101010);
}

.outcomes-card-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    height: fit-content;
    padding-inline: 4px;
    background-color: #EDF7FF;
}

.fit-content {
    width: fit-content !important;
    height: fit-content !important;
}

.outcomes-card-chart {
    border-top: 4px solid #004B87;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-top: 8px;
    padding-left: 6px;
}

.pain-chart-header-wrapper {
    background-color: transparent;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    padding: 10px 0 10px 16px;
    margin-inline: -16px;
    background-color: #004B87;
    color: #fff !important;

    &__back-icon {
        grid-column: 1/4;
        place-self: start;
    }
}

.native-header {
    text-align: center;
    @include font-properties(18px, 300, normal, #101010);
    margin: 0;
    color: inherit;
    place-self: start;
    grid-column: 5/13;
}

.patient-goals-wrapper {
    .patient-goals-title {
        margin-top: 16px;
    }
    .patient-goal {
        display: flex;
        align-items: flex-start;
        gap: 4px;
    }
}

.x-axis-legend-wrapper {
    background-color: #EDF7FF;
    color: #606A7B;
    font-size: 10px;
    width: fit-content;
    padding-inline: 4px;
    border-radius: 3px;
    margin-top: 12px;

    .x-axis-legend:not(:last-child) {
        margin-right: 8px;
    }
}

.symbol-legend-outer-wrapper {
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px 0;
    @include font-properties(10px, 400, normal, #000);
    margin-bottom: 12px;
    margin-top: 16px;

    .symbol-legend-wrapper {
        margin: 0;
        .legend-symbol {
            padding: 6px 7px;
            border-radius: 50%;
            margin-right: 4px;
            position: relative;
        }

        .legend-symbol-text {
            position: relative;
            top: 0;
            left: 0;
        }
        .legend-symbol-due {
            background-color: $surveyDueColor;
        }
        .legend-symbol-missing {
            background-color: $surveyMissedColor;
        }
        .legend-symbol-upcoming {
            background-color: $surveyUpComingColor;
        }
        .legend-symbol-no-pain {
            color: $colorWhite;
            background-color: $colorAlertPositiveDark;
            padding: 6px 4px;
        }
    }
}

.trial-chart-wrapper {
    padding-top: 12px;
}

.uppercase {
    text-transform: uppercase;
}

.pain-score-chart-loader-wrapper {
    max-width: 100%;
    height: 100%;
    margin-top: -83px;
    padding: 16px;
    padding-bottom: 24px;
    overflow-y: hidden;
    max-height: 104px;

    .full-screen-loader-wrapper .full-screen-loader {
        border-radius: 4px;
    }
}